<template>
  <div>
    <fieldset class="form-group">
      <legend class="bv-no-focus-ring col-form-label pt-0">{{ label }}:</legend>
      <div class="input-group" role="group">
        <input
          v-model="value"
          class="form-control"
          readonly="readonly"
          type="text"
        />
        <div class="input-group-append">
          <button
            class="form-control pointer"
            :disabled="disabled"
            style="width: 40px"
            type="button"
            @click="onOpen()"
          >
            <i class="fa fa-edit i-blur-edit" />
          </button>
        </div>
      </div>
      <error
        v-if="error"
        class="mt-n1"
        :message="errorMsg"
        variant="no-border no-background"
      />
    </fieldset>
    <custom-modal
      id="blur-modal"
      ref="blur-modal-ref"
      :no-close-on-back-drop="true"
      size="lg"
    >
      <div class="col-sm-12 text-center">
        <div
          class="img-to-blur-container"
          :style="{
            backgroundColor: imageBackgroundColor,
          }"
        >
          <div
            class="img-to-blur-container"
            :style="{
              backgroundImage: `url('${imageSrc}')`,
              filter: `blur(${currentSize})`,
            }"
          />
          <div class="img-to-blur-text" mt-n3>
            <h1 v-if="sampleText" :style="{ color: sampleTextColor }">
              {{ sampleText }}
            </h1>
            <p v-if="sampleSubText" :style="{ color: sampleSubTextColor }">
              {{ sampleSubText }}
            </p>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row justify-content-center align-items-center mt-4"
      >
        <h3>{{ currentSize }}</h3>
      </div>
      <div class="col-sm-12 mt-4">
        <input
          v-model="currentValue"
          class="slider col-sm-12 p-0"
          max="20"
          min="0"
          step="1"
          type="range"
        />
      </div>

      <div class="col-sm-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="float-left"
          icon-class="far fa-ban"
          :on-click="onCancel"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right"
          icon-class="fal fa-arrow-alt-circle-right"
          :on-click="onSave"
          :text="$t('continue')"
        />
      </div>
    </custom-modal>
  </div>
</template>

<script>
import Error from '@/components/basics/error/CustomError.vue';
import CustomModal from '@/components/basics/modal/CustomModal.vue';

export default {
  name: 'FilterBackgroundBlur',
  components: {
    Error,
    CustomModal,
  },
  data() {
    return {
      currentValue: null,
      imageFile: null,
      imageSrc: null,
    };
  },
  props: {
    label: {
      type: String,
      default: 'Blur Rate',
      required: true,
      meta: {
        description: 'Label text',
      },
    },
    value: {
      type: String,
      default: () => '',
    },
    file: {
      type: [File, Object],
      default: () => null,
    },
    sampleText: {
      type: String,
      default: () => '',
    },
    sampleTextColor: {
      type: String,
      default: () => '#fff',
    },
    sampleSubText: {
      type: String,
      default: () => '',
    },
    sampleSubTextColor: {
      type: String,
      default: () => '#fff',
    },
    error: {
      type: String,
      default: '',
      meta: {
        description: 'Error state',
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    imageBackgroundColor: {
      type: String,
      default: '#fff',
    },
    measurementUnit: {
      type: String,
      default: 'px',
      meta: {
        description: 'Example: pt, rem, px, etc.',
      },
    },
    errorMsg: {
      type: String,
      default: () => '',
      meta: {
        description: 'Error Message',
        example: 'This field is required',
      },
    },
  },
  computed: {
    currentSize() {
      return `${this.currentValue}${this.measurementUnit}`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.currentValue = val ? parseInt(val.replace(/^\D+/g, '')) : 0;
      },
    },
    file: {
      immediate: true,
      handler(val) {
        let result = null;

        if (val && val.name) {
          var reader = new FileReader();
          reader.onload = () => (this.imageSrc = reader.result);
          reader.readAsDataURL(this.file);
        } else if (val && val.publicUrl) {
          this.imageSrc = val.publicUrl;
        }

        return result;
      },
    },
  },
  methods: {
    async onChanged(event) {
      this.currentValue = event.value;
    },
    onOpen() {
      this.$refs['blur-modal-ref'].show();
    },
    onCancel() {
      this.$refs['blur-modal-ref'].hide();
    },
    onSave() {
      this.$emit('input', `${this.currentValue}px`);

      this.$refs['blur-modal-ref'].hide();
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .form-control:disabled,
::v-deep .form-control[readonly] {
  background-color: $color-white;
}
::v-deep .input-group-append {
  align-items: center;
}
i {
  color: $color-font-secondary;
  position: absolute;
}

.img-to-blur {
  width: 300px;
  height: 300px;
}

.i-blur-edit {
  display: block !important;
  line-height: 0;
}

.img-to-blur-container {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

.img-to-blur-text {
  position: absolute;
  top: 38%;
  left: 31%;
  margin-left: auto;
  margin-right: auto;
  width: 300px;
}

.img-to-blur-text h1 {
  font-size: 2.5rem;
  font-weight: bolder;
}

.img-to-blur-text p {
  font-size: 14px;
  font-weight: bolder;
}

input[type='range'] {
  outline: 0;
  border: 0;
  border-radius: 500px;
  width: 100%;
  max-width: 100%;
  transition: box-shadow 0.2s ease-in-out;
  // Chrome
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    & {
      height: 1.4rem;
    }
    &::-webkit-slider-runnable-track {
      color: #444;
      transition: box-shadow 0.2s ease-in-out;
    }
  }
  // Firefox
  &::-moz-range-progress {
    background-color: #ff8105;
  }
  &::-moz-range-track {
    background-color: #444;
  }
  // IE
  &::-ms-fill-lower {
    background-color: #ff8105;
  }
  &::-ms-fill-upper {
    background-color: #ff8105;
  }
}
</style>
