<template>
  <b-row>
    <content-header :title="$t('pwaCreation')" />
    <b-col cols="12">
      <b-card border-variant="light">
        <validation-observer ref="pwaFormRef" novalidate>
          <b-row>
            <b-col lg="6" md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.companyId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="text"
                  fieldvalue="value"
                  :label="$t('company')"
                  :options="collections.companies"
                  @on-selection-changed="value.kioskId = null"
                />
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider v-slot="{ errors }" rules="required">
                <filter-select
                  v-model="value.locationConfigurationId"
                  :clearable="false"
                  :error="errors[0]"
                  :error-msg="$t('mustSelectOne')"
                  fieldtext="name"
                  fieldvalue="id"
                  :label="$t('activeLocation')"
                  :options="collections.locations"
                  :tooltip-text="$t('onlyLocationsThatDoNotHaveAPWA')"
                  @on-selection-changed="value.kioskId = null"
                />
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="6">
              <validation-provider v-slot="{ errors }" rules="required|max:64">
                <base-input
                  v-model="value.name"
                  :error="errors[0]"
                  :error-msg="$t('invalidField')"
                  :label="$t('pwaName')"
                />
              </validation-provider>
            </b-col>
            <b-col md="6">
              <filter-select
                v-model="value.kioskId"
                :clearable="true"
                fieldtext="name"
                fieldvalue="id"
                :label="$t('defaultKiosk')"
                :options="collections.kioskList"
              />
            </b-col>
          </b-row>
          <div class="row">
            <sub-header :title="$t('appointmentFields')" />
          </div>
          <b-row>
            <b-col lg="3" md="3">
              <b-form-checkbox
                v-model="value.requireRedirectToAppointment"
                class="mb-1"
                switch
              >
                {{ $t('requiresReferralToAppointments') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
            <b-row>
            <b-col md="3">
              <b-form-checkbox
                v-model="value.useR8Appointment"
                class="mb-1"
                switch
              >
                {{ $t('useR8Appointment') }}
              </b-form-checkbox>
            </b-col>
            <b-col class="pt-2" md="3" offset-md="6">
              <b-button
                v-if="hideSubmitButton === undefined"
                class="mt-4 float-right"
                variant="primary"
                @click="onSubmitPWA()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
    </b-col>
    <slot />
    <b-col v-if="editMode" cols="12">
      <validation-observer ref="pwaLayoutFormRef" novalidate>
        <div class="col-12">
          <div class="row">
            <div class="col-12 my-4">
              <h4 class="font-weight-bolder float-left">
                {{ $t('specialFields') }}
              </h4>
              <hr />
            </div>
          </div>

          <div class="row card border-light">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-6">
                  <b-form-checkbox
                    v-model="value.layout.showTakeTurnButton"
                    class="mb-1"
                    switch
                  >
                    {{ $t('showTakeTurnButton') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="value.layout.showRowCounter"
                    class="mb-1"
                    switch
                  >
                    {{ $t('showRowCounter') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="value.layout.attendByPhone"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('attendByPhone') }}
                  </b-form-checkbox>
                </div>
                <div class="col-6">
                  <b-form-checkbox
                    v-model="value.layout.turnTracking"
                    class="mb-1"
                    switch
                  >
                    {{ $t('showTurnTrackingButton') }}
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="value.layout.showMobile"
                    class="mb-1"
                    switch
                  >
                    {{ $t('showInAppMobile') }}
                  </b-form-checkbox>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-md-4">
                      <b-form-checkbox
                        v-model="value.layout.showWaitTime"
                        class="mt-4"
                        switch
                      >
                        {{ $t('showWaitTime') }}
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-8">
                      <validation-provider v-slot="{ errors }" rules="max:256">
                        <base-input
                          v-model="value.layout.textWaitingTime"
                          :disabled="!value.layout.showWaitTime"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                          :label="$t('messageWaitingTime')"
                        />
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-12 my-4">
              <h4 class="font-weight-bolder float-left">
                {{ $t('startMessage') }}
              </h4>
              <hr />
            </div>
            <div class="col-md-12 card border-light py-4">
              <div class="row">
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.layout.showStartMessage"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('showStartMessage') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-4">
                  <custom-image-input
                    v-model="value.layout.startMessageBackgroundImage"
                    :auto-delete="false"
                    :file="value.layout.startMessageBackgroundImageFile"
                    :label="$t('backgroundImage')"
                    :max-height="700"
                    :max-width="700"
                    @clearFile="onDeleteImage('startMessageBackgroundImage')"
                  />
                </div>
                <div class="col-md-12">
                  <b-form-checkbox
                    v-model="value.layout.showEnglishStartMessage"
                    class="mb-1 float-right"
                    switch
                  >
                    {{ $t('showEnglish') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <editor
                    v-model="currentStartMessageText"
                    :init="editorInit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-12 my-4">
              <h4 class="font-weight-bolder float-left">
                {{ $t('commentScreen') }}
              </h4>
              <hr />
            </div>
            <div class="col-md-12 card border-light py-4">
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                    v-model="value.layout.requestComment"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('showCommentScreen') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    v-model="value.layout.commentRequired"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('commentRequired') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.commentScreenHeader"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.commentScreenHeaderEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerTextEN')"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-12 my-4">
              <h4 class="font-weight-bolder float-left">
                {{ $t('languageScreen') }}
              </h4>
              <hr />
            </div>
            <div class="col-md-12 card border-light py-4">
              <div class="row">
                <div class="col-md-4">
                  <b-form-checkbox
                    v-model="value.layout.requestLanguage"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('showLanguageScreen') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-4">
                  <b-form-checkbox
                    v-model="value.layout.languageRequired"
                    class="mb-1 float-left"
                    switch
                  >
                    {{ $t('languageRequired') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.languageScreenHeader"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.languageScreenHeaderEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerTextEN')"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-row>
          <b-col class="my-4" cols="12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('mainScreen') }}
            </h4>
            <hr />
          </b-col>
          <b-col>
            <b-card border-variant="light">
              <b-row class="align-items-end">
                <div class="col-md-12">
                  <div class="row">
                    <sub-header :title="$t('header')" />
                  </div>
                </div>
                <b-col xl="2" lg="6">
                  <custom-image-input
                    v-model="value.layout.headerBackgroundImage"
                    :auto-delete="false"
                    :file="value.layout.headerBackgroundImageFile"
                    :label="$t('backgroundImage')"
                    :max-height="300"
                    :max-width="300"
                    @clearFile="onDeleteImage('headerBackgroundImage')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-background-blur
                    v-model="value.layout.headerBackgroundImageBlurSize"
                    :disabled="isBlurDisabled"
                    :file="
                      value.layout.headerBackgroundImage != null
                        ? value.layout.headerBackgroundImage
                        : value.layout.headerBackgroundImageFile
                    "
                    :image-background-color="value.layout.headerBackgroundColor"
                    :label="$t('blurRate')"
                    :sample-sub-text="value.layout.headerSecondaryText"
                    :sample-sub-text-color="
                      value.layout.headerSecondaryTextColor
                    "
                    :sample-text="value.layout.headerText"
                    :sample-text-color="value.layout.headerTextColor"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <custom-image-input
                    v-model="value.layout.headerLogo"
                    :auto-delete="false"
                    :file="value.layout.headerLogoFile"
                    :label="$t('headerLogoImage')"
                    :max-height="150"
                    :max-width="250"
                    @clearFile="onDeleteImage('headerLogo')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.headerTextColor"
                    :label="$t('textColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.headerSecondaryTextColor"
                    :label="$t('headerSecondaryTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.headerBackgroundColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerSecondaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerSecondaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.headerSecondaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('headerSecondaryTextEN')"
                    />
                  </validation-provider>
                </div>
              </b-row>

              <div class="row mt-4">
                <div class="col-md-12">
                  <div class="row">
                    <sub-header :title="$t('buttons')" />
                  </div>
                </div>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.homeButtonColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.headerBorderColor"
                    :label="$t('borderColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.homeButtonIconColor"
                    :label="$t('iconColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.homeButtonPrimaryTextColor"
                    :label="$t('mainTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.homeButtonSecondaryTextColor"
                    :label="$t('secondaryTextColor')"
                  />
                </b-col>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="my-4" cols="12">
            <h4 class="font-weight-bolder float-left">
              {{ $t('content') }}
            </h4>
            <hr />
          </b-col>
          <b-col>
            <b-card border-variant="light">
              <b-row class="align-items-end">
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentButtonColor"
                    :label="$t('buttonColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentButtonBorderColor"
                    :label="$t('buttonBorderColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentIconsColor"
                    :label="$t('iconColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentPrimaryTextColor"
                    :label="$t('mainTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentSecondaryTextColor"
                    :label="$t('secondaryTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentLinkColor"
                    :label="$t('linksColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentTextColor"
                    :label="$t('mainScreensTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentBackgroundColor"
                    :label="$t('backgroundColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentCancelButtonBackgroundColor"
                    :label="$t('cancelButtonBackgroundColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.contentCancelButtonTextColor"
                    :label="$t('cancelButtonTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.footerButtonBackgroundColor"
                    :label="$t('footerButtonBackgroundColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.footerButtonTextColor"
                    :label="$t('footerButtonTextColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.datePickerBackgroundColor"
                    :label="$t('datePickerBackgroundColor')"
                  />
                </b-col>
                <b-col xl="2" lg="6">
                  <filter-color
                    v-model="value.layout.datePickerTextColor"
                    :label="$t('datePickerTextColor')"
                  />
                </b-col>
              </b-row>
              <hr />
              <div class="row">
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentCheckInPrimaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('checkInPrimaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentCheckInPrimaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('checkInPrimaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentCheckInSecondaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('checkInSecondaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentCheckInSecondaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('checkInSecondaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentFollowPrimaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('followPrimaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentFollowPrimaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('followPrimaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentFollowSecondaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('followSecondaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentFollowSecondaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('followSecondaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentAppointmentPrimaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('appointmentPrimaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentAppointmentPrimaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('appointmentPrimaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentAppointmentSecondaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('appointmentSecondaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentAppointmentSecondaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('appointmentSecondaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-12">
                  <hr />
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentTurnTrackingPrimaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('searchTurnPrimaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentTurnTrackingPrimaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('searchTurnPrimaryTextEN')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentTurnTrackingSecondaryText"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('searchTurnSecondaryText')"
                    />
                  </validation-provider>
                </div>
                <div class="col-lg-3 col-md-12">
                  <validation-provider v-slot="{ errors }" rules="max:64">
                    <base-input
                      v-model="value.layout.contentTurnTrackingSecondaryTextEN"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('searchTurnSecondaryTextEN')"
                    />
                  </validation-provider>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </validation-observer>
    </b-col>
    <b-col class="my-5 text-right" cols="12">
      <base-filled-button
        bg-color="#707070"
        class="float-left"
        icon-class="fas fa-ban"
        :on-click="OnCancel"
        :text="$t('cancel')"
      />

      <b-button
        v-if="editMode"
        class="mr-2"
        variant="outline-primary"
        @click="onSubmitPWALayout()"
      >
        <i class="far fa-save" /> {{ $t('save') }}
      </b-button>
      <b-button
        v-if="editMode"
        variant="primary"
        @click="onSubmitPWALayout(true)"
      >
        <i class="far fa-arrow-alt-circle-right" /> {{ $t('saveClose') }}
      </b-button>
      <slot name="buttons" />
    </b-col>
  </b-row>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import BaseInput from '@/components/BaseInput.vue';
import CustomImageInput from '@/components/CustomImageInput';
import ContentHeader from '@/components/Header';
import FilterBackgroundBlur from './components/FilterBackgroundBlur';
import Editor from '@tinymce/tinymce-vue';
import SubHeader from '@/components/SubHeader.vue';
import { submitPWA as _submitPWA } from '@/services/PWAService';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationListForPWA as _getLocationListForPWA } from '@/services/LocationService';
import {
  submitPWALayout as _submitPWALayout,
  deleteImage as _deleteImage,
} from '@/services/PWAService';
import { getKioskList as _getKioskList } from '@/services/KioskService';

export default {
  name: 'Create',
  components: {
    BaseInput,
    CustomImageInput,
    ContentHeader,
    FilterBackgroundBlur,
    Editor,
    SubHeader,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    hideSubmitButton: {
      type: undefined,
      default: () => undefined,
    },
  },
  data() {
    return {
      collections: {
        companies: [],
        locations: [],
        kioskList: [],
      },
    };
  },
  mixins: [sessionState],
  computed: {
    editMode() {
      return this.$route.name === 'PWAEdit';
    },
    isBlurDisabled() {
      return (
        this.value.layout.headerBackgroundImage == null &&
        this.value.layout.headerBackgroundImageFile == null
      );
    },
    editorInit() {
      return {
        height: 500,
        menubar: false,
        plugins:
          'advlist autolink lists link image charmap print preview anchor formatpainter searchreplace visualblocks code fullscreen insertdatetime media table paste textcolor',
        toolbar:
          'undo redo | formatselect | forecolor bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist | link image',
      };
    },
    currentStartMessageText: {
      get: function () {
        if (!this.value.layout.showEnglishStartMessage) {
          return this.value.layout.startMessageText ?? '';
        } else {
          return this.value.layout.startMessageTextEN ?? '';
        }
      },
      set: function (newVal) {
        if (!this.value.layout.showEnglishStartMessage) {
          this.value.layout.startMessageText = newVal;
        } else {
          this.value.layout.startMessageTextEN = newVal;
        }
      },
    },
  },
  mounted() {
    // load current state of this view
    const previousStateData =
      this.loadAndRemoveSessionData('registeredPwaData');
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
  watch: {
    'value.companyId': {
      immediate: true,
      async handler(value) {
        this.collections.locations = [];
        if (value) await this.getAvailableLocalizations(value);
      },
    },
    'value.locationConfigurationId': {
      immediate: true,
      async handler(value) {
        this.collections.kioskList = [];
        if (value)
          await this.getKioskList(
            this.value.companyId,
            this.value.locationConfigurationId,
          );
      },
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          this.collections.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationListForPWA(companyId, true, this.value.pwaClientId)
        .then((response) => {
          this.collections.locations = response.data;
          if (this.value.locationConfigurationId) {
            const location = this.collections.locations.find(
              (x) => x.id === this.value.locationConfigurationId,
            );
            if (!location) this.value.locationConfigurationId = null;
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },

    getKioskList(companyId, locationConfigurationId) {
      _getKioskList(companyId, locationConfigurationId)
        .then((response) => {
          this.collections.kioskList = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmitPWA() {
      if (!(await this.$refs.pwaFormRef.validate())) return;

      _submitPWA({
        pwaClientId: this.value.pwaClientId,
        companyId: this.value.companyId,
        locationConfigurationId: this.value.locationConfigurationId,
        name: this.value.name,
        requireRedirectToAppointment: this.value.requireRedirectToAppointment,
        kioskId: this.value.kioskId,
        useR8Appointment: this.value.useR8Appointment,
      })
        .then((response) => {
          this.ShowSuccessSaveToast();

          const location = this.collections.locations.find(
            (x) => x.id === this.value.locationConfigurationId,
          );
          if (location) this.value.locationConfigurationName = location.name;

          if (this.editMode) this.$emit('submitted');
          else
            this.$router.push({
              name: 'PWAEdit',
              params: { id: response.data },
            });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));

      // Persist current state of this view
      this.saveSessionData('registeredPwaData', Object.assign({}, this.$data));
    },
    async OnCancel() {
      this.$router.push({ name: 'PWAsList' });
    },
    async onSubmitPWALayout(close = false) {
      if (!(await this.$refs.pwaLayoutFormRef.validate())) return;

      _submitPWALayout({
        ...this.value.layout,
        pwaClientId: this.value.pwaClientId,
      })
        .then((response) => {
          this.ShowSuccessSaveToast();
          if (response && response.data) {
            this.value.layout.pwaClientDesignConfigurationId = response.data;
          }

          if (close) this.$router.push({ name: 'PWAsList' });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onDeleteImage(imageKey) {
      _deleteImage({
        pwaClientId: this.value.pwaClientId,
        imageKey: imageKey,
      })
        .then(() => {
          this.value.layout[imageKey] = null;
          this.value.layout[`${imageKey}File`] = null;
          this.value.layout[`${imageKey}BlurSize`] = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    // onBlurChange(val) {
    //   this.value.layout.headerBackgroundImageBlurSize = val;
    // },
  },
};
</script>

<style lang="scss" scoped>
h4.font-weight-bolder {
  background-color: $color-app-background;
  padding-right: 10px;
}
</style>
