import {
  apiTurnos
} from '@/core/api/';
import {
  serialize
} from 'object-to-formdata'

export const getPWAs = (payload) => apiTurnos.get('/api/pwa/search',{ params : { ...payload }});

export const submitPWA = model =>
  apiTurnos[model.pwaClientId ? 'put' : 'post']('/api/pwa', model);

export const getPWA = id => apiTurnos.get(`/api/pwa/${id}`);

export const submitPWALayout = model => {

  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true
  });

  return apiTurnos[model.pwaClientDesignConfigurationId ? 'put' : 'post'](
    '/api/pwa/layout',
    payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

export const deleteImage = (payload) => apiTurnos.delete(`/api/pwa/${payload.pwaClientId}/image/delete`, { data : { ...payload }});

export const deletePWA = (payload) => apiTurnos.delete(`/api/pwa/${payload}`);

export const createPWAClone = (payload) => apiTurnos.post(`/api/pwa/${payload.pwaClienId}/clone`, payload);
