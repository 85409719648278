<template>
  <b-container class="mt-5" fluid>
    <create class="my-3" :value="pwa" @submmited="loadPWA(pwaClientId)" />
  </b-container>
</template>

<script>
import Create from './Create';
import { getPWA as _getPWA } from '@/services/PWAService';

export default {
  name: 'PWAMaster',
  components: {
    Create,
  },
  created() {},
  data() {
    return {
      pwa: {
        pwaClientId: null,
        companyId: null,
        locationConfigurationId: null,
        locationConfigurationName: null,
        name: null,
        requireRedirectToAppointment: false,
        useR8Appoinment: null,
        layout: {
          pwaClientId: null,
          pwaClientDesignConfigurationId: null,
          headerBackgroundColor: null,
          headerLogo: null,
          headerBackgroundImage: null,
          startMessageBackgroundImage: null,
          headerText: null,
          headerTextColor: null,
          headerSecondaryTextColor: null,
          headerSecondaryText: null,
          headerBorderColor: null,
          contentButtonColor: null,
          contentButtonBorderColor: null,
          contentIconsColor: null,
          contentPrimaryTextColor: null,
          contentSecondaryTextColor: null,
          contentLinkColor: null,
          contentTextColor: null,
          contentBackgroundColor: null,
          contentCheckInPrimaryText: null,
          contentCheckInSecondaryText: null,
          contentFollowPrimaryText: null,
          contentFollowSecondaryText: null,
          contentAppointmentPrimaryText: null,
          contentAppointmentSecondaryText: null,
          footerButtonBackgroundColor: null,
          footerButtonTextColor: null,
          homeButtonColor: null,
          homeButtonIconColor: null,
          homeButtonPrimaryTextColor: null,
          homeButtonSecondaryTextColor: null,
          contentCancelButtonBackgroundColor: null,
          contentCancelButtonTextColor: null,
          attendByPhone: false,
          requestComment: false,
          commentRequired: false,
          commentScreenHeader: null,
          commentScreenHeaderEN: null,
          datePickerBackgroundColor: null,
          datePickerTextColor: null,
          turnTracking: false,
          contentTurnTrackingPrimaryText: null,
          contentTurnTrackingPrimaryTextEN: null,
          contentTurnTrackingSecondaryText: null,
          contentTurnTrackingSecondaryTextEN: null,
          showMobile: false,
          showWaitTime: false,
          textWaitingTime: null,
          languageScreenHeader: null,
          languageScreenHeaderEN: null
        },
      },
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        if (val.name === 'PWAEdit') {
          const pwaClientId = Number(val.params.id);
          this.loadPWA(pwaClientId);
          this.pwa.pwaClientId = pwaClientId;
        } else {
          this.pwa.pwaClientId = null;
        }
      },
    },
  },
  methods: {
    loadPWA(id) {
      _getPWA(id)
        .then((response) => {
          const data = response.data;

          const layout =
            {
              ...data.pwaClientLayoutDesignConfiguration,
              headerLogo: null,
              headerBackgroundImage: null,
            } || this.pwa.layout;

          this.pwa = {
            ...this.pwa,
            companyId: data.companyId,
            locationConfigurationId: data.locationConfigurationId,
            locationConfigurationName: data.locationConfigurationName,
            name: data.name,
            requireRedirectToAppointment: data.requireRedirectToAppointment,
            useR8Appointment: data.useR8Appointment,
            kioskId: data.kioskId,
            layout,
          };
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.$router.push({ name: 'PWARegistered' });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
