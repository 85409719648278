<template>
  <div>
    <DeleteModal
      v-model="showDeleteModal"
      error-message="pwaNameIsNotCorrect"
      :item="selectedPWA"
      label="pwaName"
      @on-confirm="onConfirmDelete()"
    />
    <content-header :title="$t('pwamaintenance')" />
    <div class="container-fluid">
      <b-container
        class="main-page-container"
        fluid
      >
        <b-row>
          <sub-header :title="$t('filterpwas')" />
        </b-row>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-3"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="getPWAs(1)"
        />
      </b-container>
      <separator
        class="my-3"
        :text="$t('pwaRegistered')"
      />
      <b-row>
        <b-col sm="12">
          <b-button
            class="text-capitalize float-right"
            variant="primary"
            @click="goToCreatePwa"
          >
            <i class="fas fa-plus" /> {{ $t('create') }}
          </b-button>
        </b-col>
        <b-col
          v-for="(pwa, index) in pwas.data"
          :key="index"
          class="mt-3"
          cols="12"
        >
          <search-result
            :value="pwa"
            @on-clone="onClonePwa"
            @on-delete="onDeletePWA(pwa)"
            @on-edit="onEditPwa"
            @on-show-preview="onShowPreview"
          />
        </b-col>
      </b-row>
      <custom-pagination
        v-if="pwas.count"
        id="pgPager"
        :data="pwas"
        :page-size="pageSize"
        @pagination-go-page="getPWAs($event)"
        @pagination-rows-per-page="getPWAs(pageIndex, $event)"
      />
      <CustomNotFound
        v-if="filtered && !pwas.data.length"
        :text="$t('noPwaFoundForFilter')"
      />

      <preview-modal
        id="preview-modal-pwa"
        ref="preview-modal-ref"
        :title="previewTitle"
      />
    </div>
  </div>
</template>

<script>
import sessionState from '@/mixins/sessionState';
import Separator from '@/components/Separator';
import SearchResult from './components/SearchResult';
import {
  getPWAs as _getPWAs,
  deletePWA as _deletePWA,
} from '@/services/PWAService';
import FilterManager from '@/components/FilterManager';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationListForPWA as _getLocationListForPWA } from '@/services/LocationService';
import CustomPagination from '@/components/CustomPagination.vue';
import contentHeader from '@/components/Header';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import PreviewModal from '@/components/PreviewModal.vue';
import SubHeader from '@/components/SubHeader.vue';
import DeleteModal from '@/components/DeleteModal';
import { PWA } from '@/config';

export default {
  name: 'RegisteredPWA',
  components: {
    SearchResult,
    FilterManager,
    CustomPagination,
    Separator,
    contentHeader,
    CustomNotFound,
    SubHeader,
    DeleteModal,
    PreviewModal,
  },
  data() {
    return {
      filters: {
        location: [],
        companies: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        name: '',
      },
      selectedPWAId: null,
      pwas: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      filtered: false,
      showDeleteModal: false,
      selectedPWA: null,
      previewTitle: null,
      pageIndex: 1,
    };
  },
  mixins: [sessionState],
  computed: {
    filtersConfig() {
      return [
        {
          rules: '',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterMultiSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          onChanged: this.onCompanyChanged,
        },
        {
          rules: '',
          name: 'location',
          label: this.$t('localization'),
          component: 'FilterMultiSelect',
          options: this.filters.location,
          placeholder: `-- ${this.$t('all')} --`,
          fieldtext: 'name',
          fieldvalue: 'id',
          clearable: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.location,
          onChanged: this.onLocationChanged,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('pwaName'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
  },
  methods: {
    async loadInitialFilters() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data;
          if (this.filters.companies.length == 1) {
            this.getAvailableLocalizations(
              this.selectedFilters.company[0].value,
            );
          }
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getAvailableLocalizations(companyId) {
      await _getLocationListForPWA(companyId)
        .then((response) => {
          this.filters.location = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.filters.location = [];
      this.selectedFilters.company = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getAvailableLocalizations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (value == null) {
        this.selectedFilters.location = [];
        return;
      }
      this.selectedFilters.location = [value];
    },
    async getPWAs(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        CompanyId: this.selectedFilters.company.length
          ? this.selectedFilters.company[0].value
          : null,
        LocationConfigurationId: this.selectedFilters.location.length
          ? this.selectedFilters.location[0].id
          : null,
        Name: this.selectedFilters.name || null,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _getPWAs(payload)
        .then(({ data }) => {
          this.pwas = data;
          this.filtered = true;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async goToCreatePwa() {
      // Persist current state of this view
      this.saveSessionData('pwaData', Object.assign({}, this.$data));

      this.$router.push({ name: 'PWACreate' });
    },
    async onEditPwa(pwa) {
      // Persist current state of this view
      this.saveSessionData('pwaData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'PWAEdit',
        params: { id: pwa.pwaClientId },
      });
    },
    async onClonePwa(pwa) {
      // Persist current state of this view
      this.saveSessionData('pwaData', Object.assign({}, this.$data));

      this.$router.push({
        name: 'PWAClone',
        params: { id: pwa.pwaClientId },
      });
    },
    onShowPreview(pwa) {
      // Show the modal
      this.previewTitle = pwa.name;

      this.$refs['preview-modal-ref'].show(
        `${PWA.BASE_URL}?company=${pwa.companyId}&location=${pwa.locationConfigurationId}&preview=1`,
      );
    },
    onDeletePWA(pwa) {
      this.showDeleteModal = true;
      this.selectedPWA = pwa;
    },
    onConfirmDelete() {
      _deletePWA(this.selectedPWA.pwaClientId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.getPWAs();
          this.selectedPWA = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
  async mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('pwaData');
    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      this.loadInitialFilters();
    }
  },
};
</script>

<style lang="scss">
#preview-modal-pwa .modal-dialog .modal-content {
  max-width: 415px !important;
  min-height: 834px !important;
}

#preview-modal-pwa .modal-body {
  padding: 0 !important;
}

#preview-modal-pwa #frmPreviewContainer {
  max-width: 413px !important;
  min-height: 768px !important;
  margin-top: 0 !important;
}

#preview-modal-pwa #frmPreview {
  width: 100% !important;
}
</style>
